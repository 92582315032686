import { Allotment } from 'allotment';
import PropTypes from 'prop-types';
import 'allotment/dist/style.css';
import React, {
  useState, useCallback, useRef, useEffect,
}
  from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLayoutDeleteIcon, HorizontalSplit, VerticalSplit } from '../../../shared/component/svgIcon';

function DynamicSplitPane(
  {
    setLayoutData, setCreate, reset, setReset,
  },
) {
  const [layout, setLayout] = useState([]); // Start with an empty layout
  const [showModal, setShowModal] = useState(false);
  const [targetPaneId, setTargetPaneId] = useState(null); // Which pane to add the new split to
  const [selectedPaneId, setSelectedPaneId] = useState(null);
  const [showAddPane, setShowAddPane] = useState(true);
  const { t } = useTranslation();
  const layoutRef = useRef(null);

  useEffect(() => {
    if (reset) {
      setLayout([]);
    }
    if (!layout?.length) {
      setCreate(false);
    }
  }, [reset]);
  // Handle opening the modal when clicking the blank space
  const openModal = (paneId = null) => {
    setTargetPaneId(paneId); // Set the pane id or null if blank space clicked
    setShowModal(true);
    setShowAddPane(false);
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
    setTargetPaneId(null);
    setShowAddPane(true);
  };

  // Add a new split (horizontal or vertical)
  const addPane = (type) => {
    const newPane = {
      id: Date.now(),
      type,
      children: [
        {
          id: Date.now() + 1,
          content: '',
          children: [],
          flex: 50,
        },
        {
          id: Date.now() + 2,
          content: '',
          children: [],
          flex: 50,
        },
      ],
    };
    setReset(false);
    // If a target pane is specified, add the new split to that pane
    if (targetPaneId) {
      const updateLayout = (nodes) => nodes.map((node) => {
        if (node.id === targetPaneId) {
          return { ...node, children: [...node.children, newPane] };
        }
        return { ...node, children: updateLayout(node?.children || []) };
      });
      setLayout(updateLayout(layout));
      setLayoutData(updateLayout(layout));
      setCreate(true);
    } else {
      // Otherwise, create a new root layout with the new split
      setLayout([newPane]);
      setLayoutData([newPane]);
      setCreate(true);
    }
    closeModal(); // Close the modal after adding the split
  };

  // Modify removePane to recalculate flex values after deletion
  const removePane = (paneId) => {
    const updateLayout = (nodes) => nodes.map((node) => {
      // Recursively update children nodes and filter out the pane to remove
      const updatedChildren = updateLayout(node.children || []).filter(
        (child) => child?.id !== paneId,
      );
      // If there are children left after deletion, adjust their flex values
      if (updatedChildren?.length && node?.children?.length > updatedChildren?.length) {
        // Create new objects for each child with the updated flex value
        updatedChildren.forEach((child) => {
          updatedChildren[updatedChildren.indexOf(child)] = { ...child, flex: 50 };
        });
      }
      // Return a new node object with updated children
      return { ...node, children: updatedChildren };
    }).filter((node) => node.id !== paneId);
    // Filter out the pane to remove
    // Update the layout with the new structure
    setLayout(updateLayout(layout));
    setLayoutData(updateLayout(layout));
    setCreate(true);
  };

  const updateFlex = (id, newFlex) => {
    const updateLayout = (nodes) => nodes?.map((node) => {
      if (node?.id === id) {
        return { ...node, flex: newFlex }; // Update flex value
      }
      return { ...node, children: updateLayout(node?.children || []) };
    });
    setLayout(updateLayout(layout));
    setLayoutData(updateLayout(layout));
    setCreate(true);
  };

  // Render the panes recursively
  const renderPane = useCallback(
    (node) => {
      if (!node?.children || node?.children?.length === 0) {
        return (
          <div
            key={node?.id}
            className="pane-container"
            onClick={(e) => {
              setSelectedPaneId(node?.id); // Set selected pane
              e.stopPropagation();
            }} // Prevent event from bubbling up when clicking inside the pane
            tabIndex={0} // Make div focusable
            role="button" // Ensure it's recognized as interactive
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSelectedPaneId(node?.id); // Handle Enter key for accessibility
              }
            }}
          >
            <div
              // type="button"
              role="presentation"
              onClick={() => removePane(node?.id)}
              className="pane-remove-btn"
              aria-label="Delete Pane"
            >
              <CustomLayoutDeleteIcon />
            </div>
            <button
              type="button"
              onClick={() => openModal(node?.id)} // Open the modal when clicking on this pane
              className="add-split-btn"
              aria-label="Add Nested Split"
            >
              {t('addNestedSplit')}
            </button>
          </div>
        );
      }

      return (
        <Allotment
          key={node?.id}
          vertical={node.type === 'vertical'}
          horizontal={node.type === 'horizontal'}
          defaultSizes={node?.flex || [50, 50]} // Ensure default flex sizes
          onChange={(sizes) => {
            if (JSON.stringify(sizes) !== JSON.stringify(node.flex)) {
              updateFlex(node?.id, sizes); // Update flex when resized
            }
          }}
        >
          {node.children.map((child) => (
            <Allotment.Pane key={child?.id}>{renderPane(child)}</Allotment.Pane>
          ))}
        </Allotment>
      );
    },
    [layout, targetPaneId, selectedPaneId],
  );

  return (
    <div className="dynamic-split-pane">
      <div className="layout-container" ref={layoutRef}>
        {layout.length === 0 ? (
          <div className="empty-state" role="none" onClick={() => openModal()}>
            {showAddPane && <span>{t('clickToAddSplit')}</span>}
          </div>
        ) : (
          layout.map((node) => (
            <Allotment key={node.id}>{renderPane(node)}</Allotment>
          ))
        )}
      </div>
      {showModal && (
        <div className="modal" role="none" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div role="none" onClick={() => addPane('vertical')} className="modal-btn">
              <HorizontalSplit />
              <p>{t('horizontalSplit')}</p>
            </div>
            <div role="none" onClick={() => addPane('horizontal')} className="modal-btn">
              <VerticalSplit />
              <p>{t('verticalSplit')}</p>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div
          className="modal-overlay"
          role="none"
          onClick={closeModal}
        />
      )}
    </div>
  );
}

DynamicSplitPane.propTypes = {
  setLayoutData: PropTypes.func.isRequired,
  setCreate: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
};

export default DynamicSplitPane;
